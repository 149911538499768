import React, { memo } from 'react'

export default memo(function ErrorsList({ errors = [] }) {
  if (!errors || !errors.length) {
    return ''
  }

  return (
    <div className="errors-list-container">
      <ul className="errors-list">
        {errors.map((error, idx) => (
          <li key={idx}>{error}</li>
        ))}
      </ul>
    </div>
  )
})
